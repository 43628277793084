
import { defineComponent, ref, onMounted, reactive } from 'vue';
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import moment from 'moment';
import useEmitter from '@/composables/Emmiter'
import { useStore } from 'vuex';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
import { getUserSelect} from '@/services/LogAcessosList' 
import { GetUsuariosSelect} from '@/models/LogsModel'

export default defineComponent({
    name: "filtro-finalizados",
    components: {
        FilterBase,
        ElConfigProvider,
    },
    setup(props, { emit }) {
        const store = useStore();
        const codUsuario = store.getters.usuarioLogado.codUsuario;        
        const optionUser = ref<GetUsuariosSelect[]>([])
        const emitter = useEmitter();
        const activeModal = ref(false);
        const closeModal = ref(false);
        const disabledClick = ref(false);
        const showClose = ref(false)
        const optionsConsultor: any = ref([]);
        const label = ref("Faça filtros por Usuário e data");
        const showGoToBack = ref(false);
        const selectUser = ref(codUsuario)
        const loading = reactive({
            user: false,
        });
        const dataAtual = moment();
        const datePicker = reactive({
            mes: dataAtual,
        })

        onMounted(async () => {
          
            loading.user = true
            const response = await getUserSelect({codUsuario})
            optionUser.value = response
            loading.user = false

        });

        async function resetDatePicker() {
            datePicker.mes = dataAtual
        };  

        async function emiteMudanca() {
            const formatDate = moment(datePicker.mes).format('M')
            const payLoad = {
                codUsuario: selectUser.value,
                mes: formatDate,
            }
            emitter.emit('filter-log-user-list', payLoad)
            closeModal.value = !closeModal.value;
        };              

        return {
            activeModal,
            closeModal,
            showClose,
            loading,
            ptBr,
            datePicker,
            emiteMudanca,
            optionsConsultor,
            resetDatePicker,
            label,
            showGoToBack,
            disabledClick,
            optionUser,
            selectUser
        };
    }
});
